export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'es-MX',
  messages: {
    'es-MX': {
      login: {
        title: 'Iniciar sesión',
        email: 'Correo electrónico',
        password: 'Contraseña',
        submit: 'Iniciar sesión',
        forgot_password: '¿Olvidaste tu contraseña?',
      },
      account_recovery: {
        title: 'Olvidé mi contraseña',
        description:
          'Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
        email: 'Correo electrónico',
        submit: 'Enviar',
      },
      reset_password: {
        title: 'Reestablecer contraseña',
        password: 'Contraseña',
        confirm_password: 'Confirmar contraseñaa',
        submit: 'Continuar',
      },
      errors: {
        ACCOUNT_RECOVERY_ERROR:
          'Hubo un error al enviar el correo electrónico. Intenta de nuevo más tarde.',
        WRONG_CREDENTIALS_ERROR: 'El usuario o la contraseña son incorrectos.',
        PASSWORD_RESET_ERROR:
          'Hubo un error al reestablecer tu contraseña. Intenta de nuevo más tarde.',
      },
      success: {
        PASSWORD_RESET_SUCCESS: 'Tu contraseña ha sido reestablecida.',
        ACCOUNT_RECOVERY_SUCCESS:
          'Hemos enviado un correo electrónico con instrucciones para restablecer tu contraseña.',
      },
    },
  },
}));
